import {Store} from "App/Store";

/**
 * Check if the string contains the query.
 * @param {string} string 
 * @param {string} query 
 * @returns {boolean}
 */
export const stringContainsQuery = (string, query) => {
	if (!string) {
		return false;
	}

	if (!query) {
		return true;
	}

	string = string.toLowerCase();
	query = query.toLowerCase();

	if (string.includes(query)) {
		return true;
	}

	const querySplit = query.split(" ");
	if (querySplit.length === 0) {
		return false;
	}

	let hasQuery = true;
	for (const word of querySplit) {
		hasQuery = hasQuery && string.includes(word);
	}

	return hasQuery;
};

/**
 * Join a user's first name and surname.
 * @param {string} firstname 
 * @param {string} surname 
 * @returns 
 */
export const getPersonName = (firstname, surname) => `${firstname || ""} ${surname || ""}`.trim();

/**
 * Takes an object containing array values only, and returns true if they are empty.
 * @param {object} object 
 * @returns {boolean}
 */
export const objectArraysEmpty = object => Object.values(object).every(arr => arr.length === 0);

/**
 * Return the org we are looking at.
 * @returns {object|null}
 */
export const getOrg = () => {
	const state = Store.getState();
	const auth = state.auth;
	const authAdmin = state.authAdmin;

	const org = state.org;
	const orgAdmin = state.orgAdmin;

	const isAdminAuthed = (authAdmin?.token !== null);

	const activeAuth = (isAdminAuthed ? authAdmin : auth);
	const activeOrgId = (isAdminAuthed ? orgAdmin : org);

	// active org
	return activeAuth?.orgs?.find?.(org => (org?.Id === activeOrgId));
};
